import React, { useState, useEffect, useLayoutEffect } from "react";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import MyLoader from "./PreLoader";
import { Button } from "./Button/Button";
import {
  defaultScraperValues,
  QueueTaskType,
  scheduleScraping,
  setDefaultValue,
  TaskType,
  Type_of_analysis,
  Type_of_reviews,
  Type_of_scarps,
} from "../../Libs/Constants";
import { TimesCross } from "../Shared/Icons";
import moment from "moment";
import ReactTooltip from "react-tooltip";

const MsgMiningDialog = ({ client, refetchReviews, config }) => {
  const [state, setState] = useState(defaultScraperValues);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    !open &&
      setState((prev) => ({
        ...prev,
        selectedOption: defaultScraperValues?.selectedOption,
      }));
  }, [open]);
  const toggleForm = () => {
    setOpen(!open);
    config?.length > 0 ? filterConfigByTask() : setState(defaultScraperValues);
  };
  const filterConfigByTask = () => {
    const isInclude = config?.some(
      (item) => item?.type === state.selectedOption.value
    );
    if (isInclude) {
      const filteredConfig = config?.find(
        (item) => item?.type === state.selectedOption.value
      );
      setState(setDefaultValue(filteredConfig));
    }
  };
  useLayoutEffect(() => {
    ReactTooltip.rebuild();
    return () => {};
  }, []);
  useEffect(() => {
    filterConfigByTask();
  }, [state?.selectedOption?.value]);
  const generateSlug = (name) =>
    name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-|-$/g, "");

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const uuid = uuidv4();
      const slug = generateSlug(client?.label || "task");

      const payload =
        state.selectedOption?.value === TaskType.crewaiMm
          ? {
              type: state.selectedOption.value,
              status: "new",
              created_at: Date.now(),
              client: client?.label,
              slug,
              id: uuid,
              scraping_frequency: state.days?.value,
              clientId: client?.value?._id,
              start_date: state.start_date,
              end_date: state.end_date,
              require_user_response: false,
              user_response: null,
              limit_users: [],
              report: state.report,
              chat: [],
              prompt: state.prompt,
              type_of_review: state.type_of_review.value,
              type_of_analysis: state.type_of_analysis.value,
              product_name_for_analysis: state.product_name_for_analysis,
            }
          : {
              created_at: Date.now(),
              client: client.label,
              limit_users: [],
              status: "new",
              config: {
                name: `${client.label} Reviews Scraper`,
                last_check: Date.now(),
                repeat: 2,
                dump: state.dump,
                clickPathBefore: state.clickPathBefore,
                clickPathAfter: state.clickPathAfter,
                type: "dump",
                url: state.url,
                scraper: "BrowserStack",
                id: uuid,
              },
              clientId: client?.value?._id,
              slug,
              type: state.selectedOption?.value,
              scraping_frequency: state.days?.value,
              type_of_review: state?.type_of_review?.value,
              type_of_scarp: state?.type_of_scarp?.value,
            };
      await refetchReviews(payload);
    } catch (error) {
      toast.error(`Invalid JSON: ${error.message}`);
      console.error("Invalid JSON:", error.message);
    }
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
    toggleForm();
  };

  return (
    <div>
      <Button className="smart-button big active" onClick={toggleForm}>
        Generate MSG Mining
      </Button>

      {open && (
        <div className="form-wrapper" id="add-new-test-form">
          <div className="form-wrapper-inner just">
            <div className="card">
              <button className="close-modal" onClick={toggleForm}>
                <TimesCross />
              </button>
              <div className="card-body">
                <h3>Generate MSG Mining</h3>
                {state.loading ? (
                  <MyLoader />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="fields-wrapper input-groups">
                      <div>
                        <label htmlFor="task-select">Select Task</label>
                        <div data-tip data-for="task-tooltip">
                          <Select
                            id="task-select"
                            required
                            onChange={(option) =>
                              handleChange("selectedOption", option)
                            }
                            value={state.selectedOption}
                            className="acc-newselectwrapper"
                            classNamePrefix="acc-newselect"
                            placeholder="Select scraper"
                            options={QueueTaskType}
                          />
                        </div>
                        <ReactTooltip
                          id="task-tooltip"
                          place="top"
                          effect="solid"
                          className="custom-tooltip"
                        >
                          If you will change the task then configuration will
                          change.
                        </ReactTooltip>
                      </div>
                      <div>
                        <label>Schedule time</label>
                        <Select
                          required
                          onChange={(option) => handleChange("days", option)}
                          value={state.days}
                          className="acc-newselectwrapper"
                          classNamePrefix="acc-newselect"
                          placeholder="Select schedule"
                          options={scheduleScraping}
                        />
                      </div>
                    </div>

                    <div className="fields-wrapper input-groups">
                      <div>
                        <label>Type of Review</label>
                        <Select
                          required
                          onChange={(option) =>
                            handleChange("type_of_review", option)
                          }
                          value={state.type_of_review}
                          className="acc-newselectwrapper"
                          classNamePrefix="acc-newselect"
                          placeholder="Select type of review"
                          options={Type_of_reviews}
                        />
                      </div>
                      {state.selectedOption.value === TaskType.webScraping && (
                        <div className="fields-wrapper">
                          <label>Type of scarp</label>
                          <Select
                            required
                            onChange={(option) =>
                              handleChange("type_of_scarp", option)
                            }
                            value={state.type_of_scarp}
                            className="acc-newselectwrapper"
                            classNamePrefix="acc-newselect"
                            placeholder="Select scarp"
                            options={Type_of_scarps}
                          />
                        </div>
                      )}
                      {state.selectedOption.value === TaskType.crewaiMm && (
                        <div>
                          <label>Report </label>
                          <input
                            placeholder=""
                            className="field-control"
                            name="report"
                            defaultValue={""}
                            value={state.report}
                            onChange={(_) =>
                              handleChange("report", _.target.value)
                            }
                          />
                        </div>
                      )}
                      {/* -------------------------crewai field------------- */}
                    </div>
                    {state.selectedOption.value === TaskType.crewaiMm && (
                      <>
                        {" "}
                        <div className="fields-wrapper">
                          <label>Type of Analysis</label>
                          <Select
                            required
                            onChange={(option) =>
                              handleChange("type_of_analysis", option)
                            }
                            value={state.type_of_analysis}
                            className="acc-newselectwrapper"
                            classNamePrefix="acc-newselect"
                            placeholder="Select Analysis"
                            options={Type_of_analysis}
                          />
                        </div>
                        <div className="fields-wrapper input-groups">
                          <div>
                            <label> Product name </label>
                            <input
                              placeholder="Product name"
                              className="field-control"
                              name="product_name_for_analysis"
                              value={state.product_name_for_analysis}
                              defaultValue={""}
                              onChange={(e) =>
                                handleChange(
                                  "product_name_for_analysis",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div>
                            <label>Prompt </label>
                            <input
                              placeholder=""
                              className="field-control"
                              name="prompt"
                              defaultValue={""}
                              value={state.prompt}
                              onChange={(e) =>
                                handleChange("prompt", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="fields-wrapper input-groups">
                          <div>
                            <div>
                              <label>Start Date</label>
                              <input
                                type="date"
                                className="field-control-date"
                                name="start_date"
                                value={moment(state.start_date).format(
                                  "YYYY-MM-DD"
                                )}
                                max={moment(state.end_date)
                                  .subtract(1, "days")
                                  .format("YYYY-MM-DD")}
                                onChange={(_) =>
                                  handleChange("start_date", _.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <label>End Date</label>
                            <input
                              type="date"
                              className="field-control-date"
                              name="end_date"
                              min={moment(state.start_date)
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              value={moment(state.end_date).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(_) =>
                                handleChange("end_date", _.target.value)
                              }
                            />
                          </div>
                        </div>{" "}
                      </>
                    )}
                    {/* ------------webscraping----------- */}
                    {state.selectedOption.value === TaskType.webScraping && (
                      <>
                        <div className="fields-wrapper">
                          <label>Last Check</label>
                          <input
                            type="date"
                            className="field-control-date"
                            name="last_check"
                            disabled={true}
                            value={moment(state.last_check).format(
                              "YYYY-MM-DD"
                            )}
                            onChange={(_) =>
                              handleChange("last_check", _.target.value)
                            }
                          />
                        </div>
                        <div className="fields-wrapper">
                          <label>Client Review Url</label>
                          <input
                            placeholder="URL"
                            className="field-control"
                            name="Url"
                            value={state.url}
                            onChange={(_) =>
                              handleChange("url", _.target.value)
                            }
                          />
                        </div>
                        <div className="fields-wrapper">
                          <label>Dump</label>
                          <textarea
                            required
                            className="field-control"
                            name="dump"
                            style={{ resize: "vertical", fontSize: "17px" }}
                            value={state.dump}
                            onChange={(_) =>
                              handleChange("dump", _.target.value)
                            }
                          />
                        </div>

                        <div className="fields-wrapper">
                          <label> Click path before</label>
                          <textarea
                            required
                            className="field-control"
                            name="clickPathBefore"
                            style={{ resize: "vertical", fontSize: "17px" }}
                            value={state.clickPathBefore}
                            onChange={(_) =>
                              handleChange("clickPathBefore", _.target.value)
                            }
                          />
                        </div>

                        <div className="fields-wrapper">
                          <label>Click path after</label>
                          <textarea
                            required
                            className="field-control"
                            name="clickPathAfter"
                            value={state.clickPathAfter}
                            onChange={(_) =>
                              handleChange("clickPathAfter", _.target.value)
                            }
                            style={{ resize: "vertical", fontSize: "17px" }}
                          />
                        </div>
                      </>
                    )}
                    <div className="fields-wrapper">
                      <Button
                        type="submit"
                        className="smart-button small active"
                        disabled={state.loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MsgMiningDialog;
