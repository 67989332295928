import React, { useCallback, useEffect, useMemo } from "react";
import NoDataFound from "../MiroComponents/NoDataFound/NoDataFound";
import { useLazyQuery } from "@apollo/client";
import { GETTESTPRECALCULATIONPROCESSING } from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";
import { SmartInput } from "../MiroComponents/SmartInput/SmartInput";
import { SmartSelector } from "../MiroComponents/SmartSelector/SmartSelector";
import SelectComponent from "../MiroComponents/SelectComponent/SelectComponent";
import { StoreIcon } from "./Icons";
import _, { set } from "lodash";

const pretypes = [
  {
    title: "CR",
    key: "crformvalues",
  },
  {
    title: "AOV",
    key: "aovrpuformvalues",
  },
  {
    title: "RPU",
    key: "aovrpuformvalues",
  },
];

export const PreCalculatedValues = ({
  precalculations,
  setFormPreData,
  formPreData,
  numberofvariants,
  setNumberofvariants,
}) => {
  const debounceSetValue = useCallback(
    _.debounce((newValue, callback) => {
      callback(newValue);
    }, 500),
    []
  );

  const handleChange = (value, callback) => {
    debounceSetValue(value, callback);
  };
  if (!precalculations?.aggregateMetrics)
    return (
      <NoDataFound
        title="No data found"
        description="Please select different filters or try again later"
      />
    );
  return (
    <div>
      <div className="seq-values">
        <p>
          Number of Weeks : <span>{precalculations?.weeks}</span>
        </p>
      </div>

      <div className="default-margin-top">
        <div className="default-padding default-border">
          <div className="flex justify-content-between default-column-gap">
            <div className="full-width">
              <SmartInput
                label="Number of variants (including control)"
                defaultValue={numberofvariants}
                type="number"
                onChange={(e) => {
                  handleChange(
                    Number(e.target.value) || 2,
                    setNumberofvariants
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <div className="impressions" id="total">
          <div className="impression-data">
            <div className="impression-heading">
              <h4>Users</h4>
            </div>
            <span className="data-count">
              {precalculations?.aggregateMetrics?.total?.users}
            </span>
          </div>

          <div className="impression-data">
            <div className="impression-heading">
              <h4>Transactions</h4>
            </div>
            <span className="data-count">
              {precalculations?.aggregateMetrics?.total?.transactions}
            </span>
          </div>

          <div className="impression-data">
            <div className="impression-heading">
              <h4>CR</h4>
            </div>
            <span className="data-count">
              {precalculations?.aggregateMetrics?.total?.cr}%
            </span>
          </div>

          <div className="impression-data">
            <div className="impression-heading">
              <h4>Purchasers</h4>
            </div>
            <span className="data-count">
              {precalculations?.aggregateMetrics?.total?.purchasers}
            </span>
          </div>
          <div className="impression-data">
            <div className="impression-heading">
              <h4>Revenue</h4>
            </div>
            <span className="data-count">
              {precalculations?.aggregateMetrics?.total?.revenue}
            </span>
          </div>
        </div>
      </React.Fragment>
      <div className="default-margin-top">
        <div className="default-padding default-border">
          <h2>Test Page data</h2>
          <div className="flex justify-content-between default-column-gap">
            <div className="full-width">
              <SmartInput
                label="Weekly traffic (sessions or users)"
                defaultValue={
                  precalculations?.aggregateMetrics?.total?.weeklyUsers
                }
                type="number"
                readOnly
              />
            </div>
            <div className="full-width">
              <SmartInput
                label="Weekly conversions"
                defaultValue={
                  precalculations?.aggregateMetrics?.total?.weeklyTransactions
                }
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      {pretypes?.map(({ title, key }, i) => (
        <div className="big-margin-top" key={i}>
          <div className="default-margin-top mde-cal-wrapper">
            <div className="default-border">
              <div className="flex justify-content-between mde-cal-inner">
                <div className="default-padding bg-dark radius-2 full-width mde-cal-form">
                  <h2>{title}, confidence level, and power</h2>
                  <div className="full-width">
                    <SmartInput
                      label={
                        title === "CR"
                          ? `Baseline conversion rate (control)`
                          : title
                      }
                      defaultValue={precalculations?.aggregateMetrics[title]}
                      className={title === "CR" ? "percent" : "money"}
                      readOnly
                    />
                  </div>
                  <div className="full-width">
                    <SmartInput
                      label="Confidence level"
                      defaultValue={
                        formPreData?.crformvalues?.confidencelevel * 100
                      }
                      onChange={(e) => {
                        const key = "crformvalues";
                        handleChange(
                          {
                            ...formPreData,
                            [key]: {
                              ...formPreData[key],
                              confidencelevel: e.target.value / 100,
                            },
                          },
                          setFormPreData
                        );
                      }}
                    />
                  </div>
                  <div className="full-width">
                    <SmartInput
                      label="Statistical power"
                      defaultValue={
                        formPreData?.crformvalues?.statisticalpower * 100
                      }
                      onChange={(e) => {
                        const key = "crformvalues";
                        handleChange(
                          {
                            ...formPreData,
                            [key]: {
                              ...formPreData[key],
                              statisticalpower: e.target.value / 100,
                            },
                          },
                          setFormPreData
                        );
                      }}
                    />
                  </div>
                  <div className="full-width">
                    <SmartSelector
                      options={[
                        {
                          label: "1-tailed",
                          value: "1-tailed",
                        },
                        {
                          label: "2-tailed",
                          value: "2-tailed",
                        },
                      ]}
                      value={{
                        label: formPreData?.crformvalues?.tailedValue,
                        value: formPreData?.crformvalues?.tailedValue,
                      }}
                      placeholder="Select Value"
                      icon={<StoreIcon />}
                      onChange={(e) => {
                        const key = "crformvalues";
                        handleChange(
                          {
                            ...formPreData,
                            [key]: {
                              ...formPreData[key],
                              tailedValue: e.value,
                            },
                          },
                          setFormPreData
                        );
                      }}
                    />
                  </div>
                </div>
                <div className=" full-width mde-cal-values default-border-right default-padding-left">
                  <h2>Number of weeks running test</h2>
                  {precalculations?.rowsExtrapolatedAovRPU?.map(
                    ({ week }, index) => (
                      <div className="full-width" key={index}>
                        <SmartInput defaultValue={week} readOnly />
                      </div>
                    )
                  )}
                </div>
                <div className="full-width mde-cal-values default-border-right">
                  <h2>Minimum detectable effect (MDE)</h2>
                  {precalculations?.rowsExtrapolatedAovRPU?.map(
                    ({ mderesult }, index) => (
                      <div
                        className={`full-width ${
                          mderesult[title]?.closest ? `closest` : ``
                        }`}
                        key={index}
                      >
                        <SmartInput
                          defaultValue={`${mderesult[title]?.mde} %`}
                          readOnly
                        />
                      </div>
                    )
                  )}
                </div>

                <div className="full-width mde-cal-values">
                  <h2>
                    Visitors per <br /> variant{" "}
                  </h2>
                  {precalculations?.rowsExtrapolatedAovRPU?.map(
                    ({ mderesult }, index) => (
                      <div className="full-width" key={index}>
                        <SmartInput
                          defaultValue={`${mderesult[
                            title
                          ]?.sampleSizePerVariant?.toLocaleString()}`}
                          readOnly
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <pre
        style={{
          color: "#fff",
        }}
      >
        <code>{precalculations?.query}</code>
      </pre>
    </div>
  );
};
