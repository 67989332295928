export const clientSurveyQuestions = [
  {
    question: "Do you use codebase to check your test data?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "How frequently are you using codebase per month/week?",
    type: "text",
  },
];

export const REPORTGRAPHTYPES = [
  {
    label: "Conversion Rate Time Series",
    value: "timeseries",
  },
  {
    label: "Probability of being a Winner",
    value: "probability",
  },
];
export const setDefaultValue = (config) => {
  return {
    selectedOption: config
      ? QueueTaskType?.find((item) => item?.value === config.type)
      : { value: "web_scraping", label: "Web Scraping" },
    days: config
      ? scheduleScraping?.find(
          (item) => item?.value === config?.scraping_frequency
        )
      : null,
    loading: false,
    start_date: config ? config.start_date : "2024-01-01",
    end_date: config ? config.end_date : "2025-09-30",
    require_user_response: config ? config?.require_user_response : false,
    user_response: config ? config?.user_response : null,
    limit_users: config ? config?.user_response : [],
    report: config ? config?.report : "",
    chat: config ? config?.chat : [],
    prompt: config ? config?.prompt : "",
    type_of_review: config
      ? Type_of_reviews?.find((item) => item?.value === config?.type_of_review)
      : "",
    type_of_analysis: config
      ? Type_of_analysis?.find(
          (item) => item?.value === config.type_of_analysis
        )
      : "",
    product_name_for_analysis: config ? config?.product_name_for_analysis : "",
    name: config ? config?.config?.name : "Reviews Scraper",
    last_check: config ? config?.created_at : Date.now(),
    repeat: config ? config?.repeat : 2,
    dump: config ? config?.config?.dump : ["article[class*='reviewCard']"],
    clickPathBefore: config
      ? config?.config?.clickPathBefore
      : ["[id*='onetrust-accept-handler']", "(sleep:5000)"],
    clickPathAfter: config
      ? config?.config?.clickPathAfter
      : ["nav[class*='pagination'] a[aria-label='Next page']"],
    type: config ? config?.type : "dump",
    url: config
      ? config?.config?.url
      : "https://www.trustpilot.com/review/qureskincare.com",
    scraper: "BrowserStack",
    type_of_scarp: config
      ? Type_of_scarps?.find((item) => item?.value === config?.type_of_scarp)
      : "",
  };
};
export const defaultScraperValues = {
  selectedOption: { value: "web_scraping", label: "Web Scraping" },
  days: null,
  showForm: false,
  loading: false,
  type_of_review: null,
  type_of_analysis: null,
  type_of_scarp: null,
  start_date: "2024-01-01",
  end_date: "2025-09-30",
  require_user_response: false,
  user_response: null,
  limit_users: [],
  report: "",
  chat: [],
  prompt: "",
  type_of_review: "",
  type_of_analysis: "",
  product_name_for_analysis: "",
  name: "Reviews Scraper",
  last_check: Date.now(),
  repeat: 2,
  dump: ["article[class*='reviewCard']"],
  clickPathBefore: ["[id*='onetrust-accept-handler']", "(sleep:5000)"],
  clickPathAfter: ["nav[class*='pagination'] a[aria-label='Next page']"],
  type: "dump",
  url: "https://www.trustpilot.com/review/qureskincare.com",
  scraper: "BrowserStack",
  type_of_scarp: "",
};

export const scheduleScraping = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "one-time", label: "One Time" },
];

export const Type_of_reviews = [
  { value: "general", label: "General" },
  { value: "specific product", label: "Specific Product" },
];
export const Type_of_scarps = [
  { value: "scarp", label: "Scarp" },
  { value: "yotpo", label: "Yotpo" },
];
export const Type_of_analysis = [
  {
    value: "analysis of positive reviews of a unique product",
    label: "analysis of positive reviews of a unique product",
  },
  {
    value: "analysis of negative reviews of a unique product",
    label: "analysis of negative reviews of a unique product",
  },
  {
    value: "general analysis of positive reviews",
    label: "general analysis of positive reviews",
  },
  {
    value: "general analysis of negative reviews",
    label: "general analysis of negative reviews",
  },
  {
    value: "general analysis of reviews about product",
    label: "general analysis of reviews about product",
  },
  {
    value: "general analysis of UX/UI reviews",
    label: "general analysis of UX/UI reviews",
  },
  {
    value: "general analysis of reviews by topics",
    label: "general analysis of reviews by topics",
  },
];
export const QueueTaskType = [
  { value: "web_scraping", label: "Web Scraping" },
  { value: "crewai_mm", label: "Crewai MM" },
];

export const TaskType = {
  webScraping: "web_scraping",
  crewaiMm: "crewai_mm",
};
